<!--
 * @Description: sim卡导入操作
 * @Author: LiangYiNing
 * @Date: 2021-12-25 17:22:14
 * @LastEditTime: 2022-10-11 15:22:13
 * @LastEditors: LiangYiNing
-->
<template lang="">
  <div class="upload-center content-view-wrapper pd-10">
    <div
      class="content-view"
      v-loading="loading"
      ref="sectionWrapper"
      :element-loading-text="pageLoadingText"
    >
      <div class="edit-wrapper">
        <div v-if="moduleKey" class="edit-wrapper__body">
          <div class="body-content">
            <el-row class="edit-title-div fixed-section">
              <el-col :span="14">
                <div class="edit-title">
                  {{ initInfo.titleName || "导入信息" }}
                  <span class="title-tip">
                    <i class="iconfont e6-icon-abnormal_line"></i>
                    请务必下载模板填写，以便成功导入信息
                  </span>
                </div>
                <!--上传组件 start-->
                <div class="uploader-wrapper">
                  <el-button
                    class="download-btn"
                    type="primary"
                    plain
                    @click="handleDownloadTemplate"
                  >
                    下载模板
                  </el-button>
                  <el-upload
                    class="uploader"
                    :file-list="fileList"
                    :action="uploadAction"
                    accept=".xls,.xlsx"
                    :multiple="false"
                    name="multipartFile"
                    :headers="{
                      Origin: host,
                      token: token
                    }"
                    :before-upload="beforeUpload"
                    :on-change="uploadOnChange"
                    :on-progress="uploadProgress"
                    :on-success="uploadSuccess"
                    :http-request="analysisXlsx"
                    :on-error="uploadError"
                  >
                    <el-button type="primary">
                      点击上传
                    </el-button>
                  </el-upload>
                  <span class="tips">只能上传.xls/.xlsx文件</span>
                  <span
                    class="tips_warning"
                    v-if="specialKey.includes(moduleKey) && fileIsParsed"
                  >
                    {{ uploadTip.parseFileWarning }}
                  </span>
                </div>
                <!--上传组件 end-->
              </el-col>
              <el-col :span="10">
                <div class="upload-tip" v-if="specialKey.includes(moduleKey)">
                  <p class="warning">
                    导入功能可支持【批量修改信息】：先导出已有数据，编辑后重新上传，现在就去
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="handleGoBack"
                    >
                      导出
                    </el-link>
                    。
                  </p>
                  <p>说明：</p>
                  <p>1、表格的内容格式、顺序不可进行变更，以防导入失败。</p>
                </div>
              </el-col>
            </el-row>
            <!-- handleForm -->
            <el-form
              ref="handleForm"
              class="handleForm"
              label-width="100px"
              :model="handleForm"
            >
              <el-form-item label="选择操作类型" prop="type">
                <el-radio v-model="handleForm.type" :label="2">
                  开通
                </el-radio>
                <el-radio v-model="handleForm.type" :label="1">
                  报停
                </el-radio>
                <el-radio v-model="handleForm.type" label="3">
                  修改卡信息
                </el-radio>
              </el-form-item>
              <el-form-item
                label="卡套餐"
                prop="simPackageId"
                v-show="handleForm.type == 3"
              >
                <e6-vr-select
                  v-model="handleForm.simPackageId"
                  :data="simPackageEnum"
                  placeholder="SIM卡套餐"
                  title="SIM卡套餐"
                  :props="{
                    id: 'simPackageId',
                    label: 'simPackageName'
                  }"
                  clearable
                ></e6-vr-select>
              </el-form-item>
              <el-form-item
                label="卡属性"
                prop="simPropId"
                v-show="handleForm.type == 3"
              >
                <e6-vr-select
                  v-model="handleForm.simPropId"
                  :data="cardAttributeEnum"
                  placeholder="卡属性"
                  title="卡属性"
                  :props="{
                    id: 'simPropId',
                    label: 'simPropName'
                  }"
                  clearable
                ></e6-vr-select>
              </el-form-item>
              <el-form-item
                label="卡类型"
                prop="simTypeId"
                v-show="handleForm.type == 3"
              >
                <e6-vr-select
                  v-model="handleForm.simTypeId"
                  :data="cardTypeEnum"
                  placeholder="卡类型"
                  title="卡类型"
                  :props="{
                    id: 'simTypeId',
                    label: 'simTypeName'
                  }"
                  clearable
                ></e6-vr-select>
              </el-form-item>
              <el-form-item
                label="是否需要报停"
                prop="needStop"
                v-show="handleForm.type == 3"
              >
                <e6-vr-select
                  v-model="handleForm.needStop"
                  :data="haveToStopEnum"
                  placeholder="是否需要报停"
                  title="是否需要报停"
                  :props="{
                    id: 'codeValue',
                    label: 'codeName'
                  }"
                  clearable
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="操作结果">
                <ul ref="printContent" class="el-card">
                  <li>{{ importMsg }}</li>
                  <li
                    v-for="(item, index) in resMsgList"
                    :key="item.msg + index"
                    :class="item.className"
                  >
                    {{ item.msg }}
                  </li>
                  <li v-if="handleState !== 'AWAIT'">
                    {{ successTotal }}条数据成功，{{ failTotal }}条数据失败。
                  </li>
                  <li v-if="handleState === 'RUNNING'">
                    执行中。。。
                  </li>
                  <li v-if="handleState === 'COMPLETE'">
                    执行完成。
                    <el-button
                      type="text"
                      v-if="failList.length"
                      @click="exportErrorByData"
                    >
                      下载操作失败列表
                    </el-button>
                  </li>
                </ul>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// mixins
import listPage from "@/mixins/list-page";
import base from "@/mixins/base";
import goBack from "@/mixins/goBack";
import concurrentRequest from "@/mixins/concurrentRequest";

import XLSX from "xlsx";
// utils
import {
  printError,
  exportXlsx,
  dateFormat,
  getKeyValueFromRouterParams
} from "@/utils/util";
import { exportXlsxByData } from "@/utils/download.js";
// interface
import {
  getUploadCenterInitInfo,
  // importAnalysisResult,
  findDownList,
  getExportTemplate,
  getPackageEnum,
  getPropEnum,
  getTypeEnum,
  updateSimBySimNo, // 通过simNo修改卡信息
  batchUpdateSimBySimNo // 通过simNo开通/报停
} from "@/api";
// 特殊提示提示页面
const SPECIAL_KEY = [];
export default {
  mixins: [base, listPage, goBack, concurrentRequest],
  name: "SimImoprtAndHandle",
  data() {
    return {
      concurrentRequestOption: {
        requestsAPI: null
      },
      handleForm: {
        simPackageId: "", //卡套餐
        simPropId: "", //卡属性
        simTypeId: "", //卡类型
        needStop: "", //是否需要报停
        type: 2 // 操作类型
      },
      handleState: "AWAIT", // AWAIT（待命） RUNNING（执行中） COMPLETE（执行完成）
      resMsgList: [],
      failList: [], // 失败结果数组，用于导出失败数据
      failTotal: 0, //失败数
      successTotal: 0, //成功数
      importMsg: "请下载模板，并导入需要操作的数据",
      simPackageEnum: [], // 卡套餐枚举
      cardAttributeEnum: [], // 卡属性枚举
      cardTypeEnum: [], // 卡类型枚举
      haveToStopEnum: [], //是否需要报停枚举
      specialKey: Object.freeze(SPECIAL_KEY),
      pageLoadingText: "",
      fileList: [], // 文件列表
      analysisLists: [], // 上传文件解析结果列表
      columns: [], //解析的表格头
      initInfo: {} // 初始数据
    };
  },
  computed: {
    // 源页面key
    moduleKey: vm => getKeyValueFromRouterParams(vm, "moduleKey"),
    // 文件上传路径
    uploadAction: () =>
      // "https://e3aly.e6gpshk.com/api/E3-BASE-FILE-UPLOAD/file/parse",
      `${process.env.VUE_APP_HOST_API ||
        ""}/api/E3-BASE-FILE-UPLOAD/file/parse`,
    // Token
    token: () => Cookies.get("E3RequestToken"),
    // host
    host: () => `${window.location.protocol}//${window.location.host}`,
    // 动态下载模板引入参数
    dynamicTemplate: vm =>
      getKeyValueFromRouterParams(vm, "dynamicTemplate") || ""
  },
  created() {
    this.moduleKey && this.getInitInfo(); // 获取初始数据
    this.initData();
  },
  methods: {
    async initData() {
      try {
        //获取枚举数据
        let promiseList = [
          findDownList(["haveToStop"]),
          getPackageEnum(),
          getPropEnum(),
          getTypeEnum()
        ];
        let [
          findDownListRes,
          packageEnumRes,
          propEnumRes,
          typeEnumRes
        ] = await Promise.all(promiseList);
        this.haveToStopEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.haveToStop"
        });
        this.simPackageEnum = this.getFreezeResponse(packageEnumRes, {
          path: "data"
        });
        this.cardAttributeEnum = this.getFreezeResponse(propEnumRes, {
          path: "data"
        });
        this.cardTypeEnum = this.getFreezeResponse(typeEnumRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },

    analysisXlsx(file) {
      this.handleReadExcel(file.file);
    },
    handleReadExcel(file) {
      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const fileData = ev.target.result;
          const workbook = XLSX.read(fileData, {
            type: "binary"
          });
          const wsname = workbook.SheetNames[0]; // 取第一张表(也可以取多个sheet)
          console.log(workbook);
          const snArr = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); // 生成json表格内容
          this.excelDataToJson(snArr, workbook.Strings);
        } catch (e) {
          console.log(e);
          return false;
        } finally {
          this.loading = false;
        }
      };
      fileReader.readAsBinaryString(file);
    }, // 表格数据转换
    excelDataToJson(list = [], titleList = []) {
      if (!list || !list.length) {
        this.$message.error("未解析出数据");
        return;
      }
      let titleListRes = [];
      // 用于提供给标题过滤试用
      const forHelpRowData = list[0];
      if (!titleList || !titleList.length) {
        titleList.map(title => {
          if (forHelpRowData.hasOwnProperty(title.t)) {
            titleListRes.push(title.t);
          }
        });
      } else {
        for (let key in forHelpRowData) {
          titleListRes.push(key);
        }
      }

      let res = [];
      list.map(item => {
        let effective = false; // 用于判断数据是否有效 判断标准：任意一个关键字段有值即为有效，空格不算
        for (let key in item) {
          item[key] += ""; // 统一转化成字符串，方便处理
          item[key] = item[key].trim() || null;
          effective = effective || item[key];
        }
        // 有效数据保存
        if (effective) {
          item.excelRowId = res.length + 1;
          res.push(item);
        }
      });
      if (!res.length) {
        this.$message.error("未解析出数据或数据无效");
        return;
      }
      console.log(titleListRes, res);
      this.columns = titleListRes; // 显示表格列
      this.analysisLists = res; // 显示表格数据
      this.importMsg = `本次导入解析出：${res.length}条SIM卡数据`;
    },
    // 上传成功回调
    uploadSuccess(res) {
      this.loading = false;
      if (res.code !== "OK") {
        this.$message.error(res.msg);
        return;
      }
      let columns = this.getFreezeResponse(res, {
        path: "data.title",
        freeze: false
      }); // 表格列
      let data = this.getFreezeResponse(res, {
        path: "data.body",
        freeze: false
      }); // 表格数据
      this.columns = columns;
      this.analysisLists = data;
      this.importMsg = `本次导入解析出：${data.length}条SIM卡数据`;
    },
    // 上传时回调
    uploadProgress() {
      this.pageLoadingText = "数据正在解析中...";
      this.loading = true;
    },
    // 文件状态改变。添加文件、上传成功和上传失败时都会被调用
    uploadOnChange(file) {
      this.fileList = [file];
    },
    // 文件上传前回调
    beforeUpload(file) {
      this.fileIsParsed = false;
      if (file && file.name && typeof file.name === "string") {
        let splitArr = file.name.split(".");
        let suffix = splitArr[splitArr.length - 1];
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (suffix !== "xls" && suffix !== "xlsx") {
          this.fileList = [];
          this.$message("只能上传.xls/.xlsx文件");
          return false;
        }
        if (!isLt2M) {
          this.fileList = [];
          this.$message("只能上传大小2M以下的文件");
          return false;
        }
        return true;
      } else {
        this.fileList = [];
        this.$message("只能上传.xls/.xlsx文件");
        return false;
      }
    },
    /* ***************************
     * 下载模版/上传相关
     * ***************************/
    // 下载模板
    async handleDownloadTemplate() {
      try {
        this.pageLoadingText = "数据正在请求中...";
        this.loading = true;
        // 动态模板调用模板下载接口
        if (this.dynamicTemplate) {
          let dynamicTemplate = JSON.parse(this.dynamicTemplate);
          let res = await getExportTemplate(dynamicTemplate);
          //解析导出信息，并下载
          exportXlsx(res);
        } else {
          // 非动态直接下载文件
          let downloadIframe = document.createElement("iframe");
          downloadIframe.setAttribute("src", this.initInfo.templateUrl || "");
          downloadIframe.setAttribute("style", "display: none");
          document.body.appendChild(downloadIframe);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 上传失败回调
    uploadError(err) {
      this.loading = false;
      this.resizeListener();
      this.$message.error("上传失败");
      printError(err);
    },
    exportErrorByData() {
      let data = this.failList.map(item => {
        let headerkey = "失败原因";
        return {
          sim: item.realNo,
          [headerkey]: item.error || item.msg
        };
      });
      exportXlsxByData({
        data,
        xlsxName: `操作失败详情-${dateFormat(
          "YYYY年mm月dd日HH时MM分SS秒",
          new Date()
        )}`
      });
    },
    // handle单个请求之前的回调 修改入参
    concurrentRequestBeforeReq(param) {
      param = {
        type: this.handleForm.type,
        realNo: param
      };
      if (this.handleForm.type == 3) {
        param = {
          ...param,
          ...this.handleForm
        };
      }
      return param;
    },
    // 单批请求结束之后的回调 统计结果
    concurrentRequestAfterReq(res) {
      if (res.code === "OK") {
        // 判断操作类型
        let path = this.handleForm.type == 3 ? "data" : "data.simStatusList";
        let msgKey = this.handleForm.type == 3 ? "msg" : "error";
        // 记录结果
        res = this.getFreezeResponse(res, { path });
        let errorList = [];
        res.map(item => {
          if (item.code === "OK" || item.code === "ok") {
            this.successTotal++;
            return;
          }
          errorList.push({
            msg: `SIM卡：${item.realNo}，操作失败，原因：${item[msgKey]}`
          });
          this.failList.push(item);
        });
        this.failTotal += errorList.length;
        this.resMsgList = [...this.resMsgList, ...errorList];
        // 滚动至底部
        this.scrollPrintContentToBottom();
      } else {
        this.resMsgList.push({
          msg: res.msg
        });
      }
    },
    async handleSubmit() {
      if (this.handleState === "RUNNING") {
        this.$message.warning("正在执行上一个操作，请稍后。");
        return;
      }
      let simNoList = this.analysisLists.map(item => {
        return item.sim;
      });
      if (!simNoList.length) {
        this.$message.warning("未导入SIM卡列表");
        return;
      }
      if (this.handleForm.type == 3) {
        let { simPackageId, simPropId, simTypeId, needStop } = this.handleForm;
        if (!simPackageId && !simPropId && !simTypeId && !needStop) {
          this.$message.warning("请选择至少一个需要修改的卡信息类型");
          return;
        }
        this.resMsgList = [
          {
            msg: "操作修改卡信息。。。"
          }
        ];
        this.concurrentRequestOption.requestsAPI = updateSimBySimNo;
      } else {
        this.resMsgList = [
          {
            msg: `操作${this.handleForm.type === 2 ? "开通" : "报停"}。。。`
          }
        ];
        this.concurrentRequestOption.requestsAPI = batchUpdateSimBySimNo;
      }
      this.failTotal = 0;
      this.successTotal = 0;
      this.failList = [];
      this.handleState = "RUNNING";
      await this.concurrentRequest(simNoList);
      this.handleState = "COMPLETE";
    },
    // 获取模块名称/模板下载路径/导入时业务方接口
    async getInitInfo() {
      try {
        this.pageLoadingText = "数据正在加载中...";
        this.loading = true;
        let res = await getUploadCenterInitInfo({
          templateKey: this.moduleKey
        });
        const initInfo = this.getFreezeResponse(res, {
          path: "data",
          defaultVal: {},
          freeze: false
        });
        this.initInfo = initInfo || {};
      } catch (err) {
        printError(err);
      } finally {
        this.loading = false;
      }
    },
    // 将输出框滚动到底部
    scrollPrintContentToBottom() {
      if (!this.$refs.printContent) return;
      this.$nextTick(() => {
        this.$refs.printContent.scrollTop = this.$refs.printContent.scrollHeight;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
@import "~@/assets/styles/variables.scss";
.upload-center {
  .body-content {
    padding: 0 20px;
    height: calc(100% - 40px);
    .edit-title-top {
      padding-top: 20px;
    }
    .edit-title-div {
      padding: 20px 0 10px 0;
    }
  }
  .edit-title {
    padding-left: 0;
    border: none;
    background: none;
    .title-tip {
      margin-left: 15px;
      font-size: 14px;
      font-weight: normal;
      color: #909399;
    }
  }
  .uploader-wrapper {
    position: relative;
    /deep/.uploader {
      display: inline-block;
      margin-left: 100px;
      .el-upload-list {
        margin-left: -100px;
      }
    }
    .tips {
      font-size: 12px;
      margin-left: 10px;
      color: #909399;
      position: absolute;
      left: 180px;
      top: 8px;
    }
    .tips_warning {
      color: $--color-status--assist-1;
      margin-left: 10px;
      font-size: 12px;
      display: inline-block;
      position: relative;
      bottom: 8px;
    }
  }
  .upload-tip {
    display: inline-block;
    width: 555px;
    // float: right;
    p {
      font-size: 14px;
      color: #909399;
      line-height: 22px;
    }
    .warning {
      color: $--color-status--assist-1;
    }
  }
  .download-btn {
    position: absolute;
    left: 0;
  }
  .table-wrapper {
    // margin-top: 10px;
    // height: calc(100% - 117px);
    /deep/ .el-table {
      .el-table__header {
        thead th .cell {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .el-button--small {
        padding: 5px 0;
      }
    }
    .import-status {
      display: inline-block;
      padding: 0 10px;
      line-height: 23px;
      border-radius: 3px;
      cursor: pointer;
      color: #ffffff;
      &.success {
        background-color: $--color-status--assist-2;
      }
      &.fail {
        background-color: $--color-status--assist-3;
      }
      &.pending {
        color: #909399;
      }
    }
  }
  /deep/ .edit-dialog {
    .edit-form-wrapper {
      padding: 20px;
      max-height: 450px;
      overflow: auto;
    }
  }
}
.fail-reason-content {
  padding: 0 10px;
  font-size: 12px;
}
.handleForm {
  margin-top: 20px;
}
.el-card {
  width: 50%;
  height: 300px;
  overflow: auto;
  word-wrap: break-word;
  padding: 0 10px 0 10px;
}
</style>
